import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Refleksjon" />
    <div className="longtext">
      <h2>Refleksjon</h2>
      <h3>Gjør om svar til oppgaver</h3>
      <ul>
        <li>
          Dersom linjen har et kalenderikon foran seg vil den også bli lagt i "I
          dag"
        </li>
        <li>
          Dersom linjen har en sirkelikon foran seg vil den også bli lagt i
          "Innboksen"
        </li>
        <li>Linjer uten ikon foran skjer det ikke noe spesielt med</li>
      </ul>

      <p>
        <b>
          Du kan skru kalender/ikon på av for hver linje med ikonene over
          tastaturet på iOS/iPad, og med høyreklikk på mac.
        </b>
      </p>

      <p>
        Og ikon eller ikke; svaret ditt blir uansett liggende i refleksjon også.
      </p>

      <h3>Legg til spørsmål</h3>
      <p>
        Du legger til et nytt spørsmål med den grønne plussknappen. Dersom du
        ikke har skrevet noe svar vil spørsmålet byttes ut med et annet. Dersom
        du har begynt å skrive noe vil det legges til et nytt spørsmål.
      </p>

      <h3>Endre spørsmål</h3>
      <p>
        Du kan velge mellom spørsmål som er relevante for et dag-, uke-, måned-
        eller årsperspektiv, avhengig av hvilket nivå du vil reflektere på.
      </p>

      <p>
        <b>For Mac:</b> høyreklikk på spørsmålet
        <br />
        <b>For iOS:</b> klikk på spørsmålet
      </p>
    </div>
  </Layout>
)

export default Page
